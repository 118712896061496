/**
 * It summarizes the order summary for the user and displays the item charges, shipping and handling charges. It then displays the estimated cost to the user for all the items in the user cart.
 *
 * @module views/Organisms/Activities
 * @memberof -Common
 */
import './Activities.scss';

import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import GridContainer from '@ulta/core/components/GridContainer/GridContainer';
import Link_Huge from '@ulta/core/components/Link_Huge/Link_Huge';
import { useAppConfigContext } from '@ulta/core/providers/AppConfigProvider/AppConfigProvider';
import { useDeviceInflection } from '@ulta/core/providers/InflectionProvider/InflectionProvider';

import Text from '../Text/Text';

/**
 * Represents a Activities component
 *
 * @method
 * @param {ActivitiesProps} props - React properties passed from composition
 * @returns Activities
 */

export const Activities = function( props ){
  const { guestGreeting, memberLabel, totalPoints, totalValue, offersAction, totalPointsLabel, totalValueLabel, setShowSpacer } = props;
  const { breakpoint } = useDeviceInflection();
  const { rewardsPointsLimit } = useAppConfigContext();
  const isMobileDevice = breakpoint.CURRENT_BREAKPOINT === 'SM' || breakpoint.CURRENT_BREAKPOINT === 'MD';
  const mobileValue = isMobileDevice && parseFloat( totalPoints?.replace( /,/g, '' ) ) >= rewardsPointsLimit ? totalValue : totalPoints;
  const isMobileValueUnderLimit = parseFloat( mobileValue?.replace( /,/g, '' ) ) < rewardsPointsLimit;
  const displayPoints = isMobileDevice ? mobileValue : totalPoints;
  const mobileValueLabel = isMobileValueUnderLimit ? totalPointsLabel : `${totalPointsLabel} ${totalValueLabel}`;
  const displayLabel = !isMobileDevice ? totalPointsLabel : mobileValueLabel;
  const mobileTitle = isMobileDevice ? 'title-sm' : 'title-5' ;
  const mobileMemberLabel = isMobileDevice ? 'body-3' : 'body-2' ;

  useEffect( () => {
    if( setShowSpacer ){
      setShowSpacer( !!guestGreeting );
    }
  }, [guestGreeting] );

  if( !guestGreeting ){
    return null;
  }

  return (
    <div className='Activities'>
      <GridContainer>
        <div className='Activities__wrapper'>
          <Link_Huge
            url={ offersAction?.url }
            unstyled={ true }
            className='Activities__link'
          >
            <span className='sr-only'>{ offersAction?.label }</span>
            <div className='Activities__container'>
              <div className='Activities__userInfo'>
                <div className='Activities__userGreeting'>
                  <Text
                    htmlTag='h2'
                    fontWeight='bold'
                    textStyle={ mobileTitle }
                  >
                    <span className='Activities__guestGreetingText'>{ guestGreeting }</span>
                  </Text>
                  <div className='Activities__userLevel'>
                    <Text textStyle={ mobileMemberLabel }
                      htmlTag='p'
                      color='neutral-700'
                    >
                      { memberLabel }
                    </Text>
                  </div>
                </div>
              </div>
              <div className='Activities__rewards'>
                <div className='Activities__points'>
                  <Text htmlTag='p'
                    textStyle={ mobileTitle }
                  >
                    { displayPoints }
                  </Text>
                  <div className='Activities__pointsLabel'>
                    <Text textStyle={ mobileMemberLabel }
                      htmlTag='p'
                      color='neutral-700'
                    >
                      { displayLabel }
                    </Text>
                  </div>
                </div>
                { !isMobileDevice && (
                  <>
                    <div className='Activities__divider'></div>
                    <div className='Activities__value'>
                      <Text htmlTag='p'
                        textStyle='title-5'
                      >
                        { totalValue }
                      </Text>
                      <div className='Activities__valueLabel'>
                        <Text textStyle='body-2'
                          htmlTag='span'
                          color='neutral-700'
                        >
                          { totalValueLabel }
                        </Text>
                      </div>
                    </div></>
                ) }
              </div>
            </div>
          </Link_Huge>
        </div>
      </GridContainer>
    </div>
  );
};

/**
  * Property type definitions
  * @typedef ActivitiesProps
  * @type {object}
  * @property {string} guestGreeting - Label for Greetig
  * @property {string} memberLabel - Label for MemberShip level
  * @property {string} totalPoints - Value for Points amount
  * @property {string} totalValue - Value for Rewards amount
  * @property {string} totalValueLabel - Label for Value
  * @property {string} totalPointsLabel - Label for Points
  * @property {object} offersAction - Action from DXL
  */
export const propTypes = {
  guestGreeting: PropTypes.string.isRequired,
  memberLabel: PropTypes.string.isRequired,
  totalPoints: PropTypes.string.isRequired,
  totalValue: PropTypes.string.isRequired,
  totalValueLabel: PropTypes.string.isRequired,
  totalPointsLabel: PropTypes.string.isRequired,
  offersAction: PropTypes.shape( {
    url: PropTypes.string
  } ).isRequired
};

Activities.propTypes = propTypes;


export default Activities;
